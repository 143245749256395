// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "./src/global.css"
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

console.log('gatsby-browser.js')
